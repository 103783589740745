// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function FaIcon(Props) {
  var classes = Props.classes;
  return React.createElement("span", {
              key: classes
            }, React.createElement("i", {
                  className: classes
                }));
}

var make = FaIcon;

export {
  make ,
}
/* react Not a pure module */
