// Generated by ReScript, PLEASE EDIT WITH CARE


function nullUnless(element, condition) {
  if (condition) {
    return element;
  } else {
    return null;
  }
}

function nullIf(element, condition) {
  if (condition) {
    return null;
  } else {
    return element;
  }
}

export {
  nullUnless ,
  nullIf ,
}
/* No side effect */
