// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "../../../../node_modules/rescript/lib/es6/js_string.js";

function randomId(param) {
  var number = Math.random().toString();
  var time = Date.now().toString();
  return Js_string.replace(".", "-", "I" + (time + number));
}

export {
  randomId ,
}
/* No side effect */
