// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var CSRFTokenMissing = /* @__PURE__ */Caml_exceptions.create("AuthenticityToken.CSRFTokenMissing");

var CSRFTokenEmpty = /* @__PURE__ */Caml_exceptions.create("AuthenticityToken.CSRFTokenEmpty");

function fromHead(param) {
  var metaTag = document.querySelector("meta[name='csrf-token']");
  if (metaTag == null) {
    throw {
          RE_EXN_ID: CSRFTokenMissing,
          Error: new Error()
        };
  }
  var token = metaTag.getAttribute("content");
  if (!(token == null)) {
    return token;
  }
  throw {
        RE_EXN_ID: CSRFTokenEmpty,
        Error: new Error()
      };
}

export {
  CSRFTokenMissing ,
  CSRFTokenEmpty ,
  fromHead ,
}
/* No side effect */
