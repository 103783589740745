// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import Data from "@emoji-mart/data";
import React$1 from "@emoji-mart/react";

var Picker = {};

function emojiDivClassName(isOpen) {
  if (isOpen) {
    return "absolute top-full -start-32 md:-translate-x-0 z-10 shadow ";
  } else {
    return "hidden";
  }
}

function EmojiPicker(Props) {
  var className = Props.className;
  var title = Props.title;
  var onChange = Props.onChange;
  var wrapperRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  React.useEffect((function () {
          var handleClickOutside = function ($$event) {
            var wrapper = wrapperRef.current;
            if (!(wrapper == null) && !wrapper.contains($$event.target)) {
              Curry._1(setIsOpen, (function (param) {
                      return false;
                    }));
            }
            
          };
          var handleEscKey = function (e) {
            var key = e.key;
            if (key === "Escape" || key === "Esc") {
              Curry._1(setIsOpen, (function (param) {
                      return false;
                    }));
            }
            
          };
          document.addEventListener("keyup", handleEscKey);
          document.addEventListener("click", handleClickOutside);
          return (function (param) {
                    document.removeEventListener("keyup", handleEscKey);
                    document.removeEventListener("click", handleClickOutside);
                  });
        }), []);
  return React.createElement("div", {
              ref: wrapperRef,
              className: "sm:relative inline-block"
            }, React.createElement("button", {
                  "aria-label": title,
                  className: className,
                  title: title,
                  type: "button",
                  onClick: (function (param) {
                      Curry._1(setIsOpen, (function (prev) {
                              return !prev;
                            }));
                    })
                }, React.createElement("i", {
                      className: "fas fa-smile"
                    })), React.createElement("div", {
                  className: "transition-all " + (
                    match[0] ? "absolute top-full -start-32 md:-translate-x-0 z-10 shadow " : "hidden"
                  )
                }, React.createElement(React$1, {
                      title: title,
                      onEmojiSelect: (function ($$event) {
                          Curry._1(onChange, $$event);
                          Curry._1(setIsOpen, (function (param) {
                                  return false;
                                }));
                        }),
                      data: Data
                    })));
}

var make = EmojiPicker;

export {
  Picker ,
  emojiDivClassName ,
  make ,
}
/* react Not a pure module */
